import { defaultTheme } from "react-admin";

export const darkTheme = {
  palette: {
    primary: {
      main: "#90caf9",
    },
    secondary: {
      main: "#FBBA72",
    },
    mode: "dark",
  },
  sidebar: {
    width: 240,
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: "3px solid #000",
          "&.RaMenuItemLink-active": {
            borderLeft: "3px solid #90caf9",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: "#ffffffb3",
          backgroundColor: "#616161e6",
        },
      },
    },
  },
};

export const lightTheme = {
  palette: {
    primary: {
      main: "#0c8774",
    },
    secondary: {
      light: "#5f5fc4",
      main: "#283593",
      dark: "#001064",
      contrastText: "#fff",
    },
    error: {
      main: "#ef5350",
    },
    background: {
      default: "#fcfcfe",
    },
    faded: {
      blue: "#1565C00a",
      darkblue: "#01579B0a",
      lightblue: "#0288D10a",
      black: "#00000099",
      white: "#FAFAFA",
    },
    common: {
      blue: "#1565C0",
      darkblue: "#01579B",
      lightblue: "#0288D1",
      black: "#000000",
      white: "#FFFFFF",
    },
    grey: {
      50: "#FAFAFA",
      100: "#F5F5F5",
      200: "#EEEEEE",
      300: "#E0E0E0",
      400: "#BDBDBD",
      500: "#9E9E9E",
      600: "#757575",
      900: "#212121",
    },
    blue: {
      400: "#42A5F5",
    },
    mode: "light",
  },
  shape: {
    borderRadius: 5,
  },
  sidebar: {
    width: 240,
    borderRight: "1px #e5e5e5 solid",
  },
  components: {
    ...defaultTheme.components,
    RaDatagrid: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(255, 255, 255, 0.002)",
          fontSize: "14px !important",
          color: "rgba(0, 0, 0, 0.87) !important",
          "& .MuiTableRow-hover": {
            transition: ".2s all",
          },
          "& .MuiTableRow-hover:hover": {
            backgroundColor: "rgba(25, 118, 210, 0.08) !important",
          },
          "& .RaDatagrid-headerCell": {
            fontWeight: "500",
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: "separate",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: "none",
        },
        root: {
          border: "1px solid #e0e0e3",
          backgroundClip: "padding-box",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: "#FFF",
          background: "linear-gradient(180deg, #2B4C5E 0%, #0C8774 100%);",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#f5f5f5",
        },
        barColorPrimary: {
          backgroundColor: "#d7d7d7",
        },
      },
    },
  },
};
