import { List } from "react-admin";
import { styled } from "@mui/material/styles";

const StyledList = styled(List)(({ theme }) => ({
  "& .Mui-active": {
    "span, svg.MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
  },
}));

export default StyledList;
