import React from "react";
import PropTypes from "prop-types";

import { Box, Typography } from "@mui/material";
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
} from "react-admin";

const ContactList = ({ accountId }) => {
  return (
    <List
      disableSyncWithLocation
      resource={`accounts/${accountId}/contacts`}
      empty={
        <Box textAlign="center" width="100%" mt={3}>
          <Typography variant="h4" paragraph>
            Sem dados
          </Typography>
        </Box>
      }
      perPage={25}
      actions={false}
    >
      <Datagrid size="medium" bulkActionButtons={false}>
        <FunctionField
          label="Nome"
          render={(record) => (
            <Box>
              <strong>{record.name}</strong>
              <br />
              <span>{record.position}</span>
            </Box>
          )}
        />
        <TextField label="Email" source="email" />
        <TextField label="Telefone" source="phone_number" />
        <DateField
          label="Dt. Criação"
          source="created_at"
          showTime
          locales="pt-BR"
        />
        <DateField
          label="Dt. Atualização"
          source="updated_at"
          showTime
          locales="pt-BR"
        />
      </Datagrid>
    </List>
  );
};

ContactList.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default ContactList;
