import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  List,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  AutocompleteInput,
  DateInput,
  Form,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
} from "react-admin";
import CurrencyInput from "../../../../components/CurrencyInput";
import { addDays } from "../../../../utils/helpers";

const IntegerInput = (props) => (
  <NumberInput
    label={false}
    defaultValue={1}
    min={1}
    onKeyDownCapture={(event) => {
      if (["-", ".", ","].includes(event.nativeEvent.key))
        event.preventDefault();
    }}
    {...props}
  />
);

const EditPayablePopup = ({
  payable,
  loading,
  onClose,
  onConfirm,
  errorMessages,
}) => {
  console.log("EditPayablePopup", payable);

  return (
    <Dialog open={!!payable} onClose={onClose}>
      <DialogTitle>Edição de Título</DialogTitle>
      <Form onSubmit={onConfirm}>
        <DialogContent>
          <ReferenceInput
            label="Sacado"
            source="sponsor_id"
            reference="accounts"
            filter={{ type: "sponsor" }}
          >
            <AutocompleteInput
              label="Sacado"
              variant="outlined"
              optionText="name"
              fullWidth
              disabled
              defaultValue={payable?.sponsor_id}
              validate={[required()]}
            />
          </ReferenceInput>
          <ReferenceInput
            label="Cedente"
            source="supplier_id"
            reference="accounts"
            filter={{ type: "supplier" }}
          >
            <AutocompleteInput
              label="Cedente"
              variant="outlined"
              optionText="name"
              disabled
              defaultValue={payable?.supplier_id}
              fullWidth
              parse={() => payable?.supplier_id}
              validate={[required()]}
            />
          </ReferenceInput>
          <DateInput
            source="expiration_date"
            label="Data de vencimento"
            fullWidth
            disabled
            defaultValue={payable?.expiration_date}
            validate={[
              required(),
              (date) =>
                new Date(date) < addDays(Date.now(), 5) &&
                "Data de vencimento deve ser superior a 5 dias da data corrente",
            ]}
          />
          <FormDataConsumer>
            {({ formData }) => (
              <CurrencyInput
                source="amount"
                label="Valor da parcela"
                disabled
                defaultValue={payable?.amount * 100}
                unit="cents"
                validate={[
                  required(),
                  (amount) =>
                    amount < 10000 &&
                    "O valor da parcela deve ser superior a R$ 100,00",
                  (amount) =>
                    amount > formData.invoice_net_amount &&
                    "O valor da parcela não pode ser superior ao valor total do contrato",
                ]}
              />
            )}
          </FormDataConsumer>
          <div fullWidth style={{ display: "flex", alignItems: "center" }}>
            Parcelas:
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  <IntegerInput
                    source="installment"
                    sx={{ margin: "0 10px" }}
                    disabled
                    defaultValue={payable?.installment}
                    validate={[
                      required(),
                      (installment) => {
                        return (
                          installment > formData.installments_total &&
                          "O número da parcela não pode ser maior do que o total de parcelas"
                        );
                      },
                    ]}
                  />
                );
              }}
            </FormDataConsumer>
            de
            <IntegerInput
              source="installments_total"
              disabled
              defaultValue={payable?.installment_total}
              sx={{ marginLeft: "10px" }}
              validate={[
                required(),
                (total) =>
                  total > 12 && "O número total máximo de parcelas é 12",
              ]}
            />
          </div>
          <CurrencyInput
            source="invoice_net_amount"
            label="Valor líquido total do contrato"
            unit="cents"
            disabled
            defaultValue={payable?.invoice_net_amount}
            validate={[required(), (v) => v <= 0 && "Obrigatório"]}
          />
          <SelectInput
            source="invoice_type"
            label="Tipo de contrato / NF do título"
            choices={[
              { id: "NFe", name: "NFe" },
              { id: "NFSe", name: "NFSe" },
              { id: "RPA", name: "RPA" },
              { id: "Outros", name: "Outros" },
            ]}
            fullWidth
          />
          <TextInput
            source="invoice_number"
            label="Número do contrato / NF do título"
            fullWidth
          />
          <DateInput
            source="invoice_issue_date"
            label="Data de emissão do contrato / NF"
            fullWidth
          />
          <TextInput
            source="invoice_key"
            label="Chave de acesso da NF se houver"
            fullWidth
            helperText={
              <Link
                href="https://enotas.com.br/blog/chave-de-acesso/"
                target="_blank"
              >
                O que é chave de acesso?
              </Link>
            }
            sx={{ marginBottom: "20px" }}
          />
          <TextInput
            source="external_id"
            label="Identificador externo"
            disabled
            defaultValue={payable?.external_id}
            fullWidth
            helperText="Chave de identificação do título fornecida pelo sacado"
            validate={[required()]}
          />
          {errorMessages?.length > 0 && (
            <div style={{ marginTop: "20px" }}>
              <Typography color="error" fontSize="1rem">
                Avisos:
              </Typography>
              <List dense disablePadding>
                {errorMessages.map((msg) => (
                  <ListItemText>
                    <Typography
                      color="error"
                      fontSize="0.75rem"
                      sx={{ marginLeft: "10px" }}
                    >
                      {`●\t`}
                      {msg}
                    </Typography>
                  </ListItemText>
                ))}
              </List>
            </div>
          )}
        </DialogContent>
        <DialogActions sx={{ margin: "20px" }}>
          {!loading && (
            <Button variant="outlined" onClick={onClose}>
              Cancelar
            </Button>
          )}
          {loading ? (
            <CircularProgress
              size={36.5}
              thickness={3}
              sx={{ padding: "4px" }}
            />
          ) : (
            <Button variant="contained" disableElevation type="submit">
              Salvar
            </Button>
          )}
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default EditPayablePopup;
