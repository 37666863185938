import AccountList from "./AccountList";
import AccountDetail from "./AccountDetail";
import UserDetail from "./UserDetail";

const accounts = {
  list: AccountList,
  show: AccountDetail,
};

const users = {
  show: UserDetail,
};

export { accounts, users };
