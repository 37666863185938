import React from "react";

import {
  ChipField,
  DateField,
  FunctionField,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { Typography } from "@mui/material";

import { BadgeColors } from "../../../utils/constants";

const WebhookDetail = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Typography variant="h5">Título</Typography>

        <TextField label="ID" source="id" />
        <TextField label="ID do Sacado" source="external_id" />
        <ReferenceField
          label="Sacado"
          source="sponsor_id"
          reference="accounts"
          sortable={false}
          link="show"
        >
          <TextField source="name" label="Sacado" sortable={false} />
        </ReferenceField>
        <ReferenceField
          label="Cedente"
          source="supplier_id"
          reference="accounts"
          sortable={false}
          link="show"
        >
          <TextField source="name" label="Cedente" sortable={false} />
        </ReferenceField>
        <FunctionField
          label="Título"
          render={(record) =>
            `${record.invoice_type} ${record.invoice_number} (${record.installment}/${record.installment_total})`
          }
        />
        <NumberField
          label="Valor"
          source="amount"
          options={{ style: "currency", currency: "BRL" }}
        />
        <NumberField
          label="Valor Líq. NF/Contrato"
          source="invoice_net_amount"
          options={{ style: "currency", currency: "BRL" }}
        />
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
        <DateField
          label="Dt. Emissão"
          source="invoice_issue_date"
          locales="pt-BR"
        />
        <DateField
          source="expiration_date"
          label="Vencimento"
          sortable={false}
          locales="pt-BR"
        />
        <TextField source="created_by" label="Criado por" />
        <DateField
          label="Dt. Criação"
          source="created_at"
          showTime
          locales="pt-BR"
        />
        <DateField
          label="Dt. Atualização"
          source="updated_at"
          showTime
          locales="pt-BR"
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default WebhookDetail;
