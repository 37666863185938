import React from "react";
import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PersonAddAlt1Rounded from "@mui/icons-material/PersonAddAlt1Rounded";
import MedicalServicesRounded from "@mui/icons-material/MedicalServicesRounded";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import { useDataProvider } from "react-admin";

const ImportSpreadsheetDialog = (props) => {
  const { onClose, onUpload, open } = props;
  const dataProvider = useDataProvider();

  return (
    <StyledDialog open={open} onClose={onClose}>
      <Topbar>
        <Title>Importar Contas</Title>
        <CloseButton onClick={onClose} />
      </Topbar>
      <CustomButtom onClick={() => onUpload("supplier")}>
        <StyledPersonAddAlt1Rounded />
        <ButtonTextContainer>
          <ButtonTitle>Planilha Cedentes</ButtonTitle>
          <ButtonText>Somente arquivos .xlsx de até 10MB</ButtonText>
        </ButtonTextContainer>
      </CustomButtom>
      <CustomButtom
        onClick={() => onUpload("sponsor")}
        sx={{ marginTop: "24px" }}
      >
        <StyledMedicalServicesRounded />
        <ButtonTextContainer>
          <ButtonTitle>Planilha Sacados</ButtonTitle>
          <ButtonText>Somente arquivos .xlsx de até 10MB</ButtonText>
        </ButtonTextContainer>
      </CustomButtom>
      <Divider>
        <DividerLine />
        <span>Outras ações</span>
        <DividerLine />
      </Divider>
      <CustomButtom onClick={dataProvider.downloadAccountSpreadsheetModel}>
        <StyledDownloadIcon />
        <ButtonTextContainer>
          <ButtonTitle>Baixar planilha modelo</ButtonTitle>
          <ButtonText>Modelo padrão utilizado para preenchimento</ButtonText>
        </ButtonTextContainer>
      </CustomButtom>
    </StyledDialog>
  );
};

const Topbar = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "18px",
}));

const CloseButton = styled(CloseRoundedIcon)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 500,
  opacity: "0.4",
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
}));

const CustomButtom = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "480px",
  padding: "12px 20px",
  border: `2px solid ${theme.palette.grey[300]}`,
  borderRadius: "8px",
  cursor: "pointer",

  ":hover": {
    border: `2px solid ${theme.palette.grey[400]}`,
    backgroundColor: "rgba(0, 0, 0, 0.08);",
  },
}));

const ButtonTextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "8px 0",
});

const StyledPersonAddAlt1Rounded = styled(PersonAddAlt1Rounded)({
  fontSize: "64px",
  color: "#4F3CC94D",
  marginRight: "20px",
});

const StyledMedicalServicesRounded = styled(MedicalServicesRounded)({
  fontSize: "64px",
  color: "#4F3CC94D",
  marginRight: "20px",
});

const StyledDownloadIcon = styled(DownloadForOfflineRoundedIcon)({
  fontSize: "64px",
  color: "#4F3CC94D",
  marginRight: "20px",
});

const ButtonTitle = styled("span")(({ theme }) => ({
  fontSize: "18px",
  fontWeight: 600,
  letterSpacing: "0.22px",
  color: "#4F3CC9",
}));

const ButtonText = styled("span")(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  letterSpacing: "0.22px",
  opacity: "0.6",
}));

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "24px",
  },
});

const Title = styled("span")(({ theme }) => ({
  fontSize: "20px",
  fontWeight: 500,
  color: theme.palette.grey[900],
}));

const Divider = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginTop: "24px",
  marginBottom: "24px",
  fontSize: "14px",
  color: theme.palette.grey[600],

  span: {
    margin: "0 16px",
  },
}));

const DividerLine = styled("div")(({ theme }) => ({
  flex: 1,
  height: "1px",
  backgroundColor: theme.palette.grey[300],
}));

export default ImportSpreadsheetDialog;
