import React from "react";
import PropTypes from "prop-types";

import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  TextField,
} from "react-admin";

import { BadgeColors } from "../../../../utils/constants";

const SponsorPayableList = ({ sponsorId }) => {
  return (
    <List
      disableSyncWithLocation
      filter={{ sponsor_id: sponsorId, status: "", supplier_id: "" }}
      resource="payables"
      perPage={25}
      actions={false}
    >
      <Datagrid bulkActionButtons={false} size="medium" rowClick="show">
        <ReferenceField
          label="Cedente"
          source="supplier_id"
          reference="accounts"
          sortable={false}
          link={false}
        >
          <TextField source="name" label="Cedente" sortable={false} />
        </ReferenceField>
        <FunctionField
          label="Título"
          render={(record) =>
            `${record.invoice_type} ${record.invoice_number} (${record.installment}/${record.installment_total})`
          }
        />
        <NumberField
          label="Valor"
          source="amount"
          options={{ style: "currency", currency: "BRL" }}
        />
        <DateField
          source="expiration_date"
          label="Vencimento"
          sortable={false}
          locales="pt-BR"
        />
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

SponsorPayableList.propTypes = {
  sponsorId: PropTypes.string.isRequired,
};

export default SponsorPayableList;
