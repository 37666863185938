import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  BooleanInput,
  email,
  Form,
  required,
  SelectInput,
  TextInput,
} from "react-admin";

const RepresentativePopup = ({ loading, open, setOpen, submitHandler }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Cadastrar Representante Legal</DialogTitle>
      <Form onSubmit={submitHandler} noValidate>
        <DialogContent>
          <TextInput
            variant="outlined"
            source="r_name"
            label="Nome Completo"
            disabled={loading}
            fullWidth
            defaultValue=""
            validate={[required("Este campo é obrigatório")]}
          />
          <SelectInput
            variant="outlined"
            source="r_document_type"
            label="Tipo Documento"
            disabled={loading}
            choices={[
              { id: "cpf", name: "CPF" },
              { id: "cnpj", name: "CNPJ" },
            ]}
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="r_document"
            label="Documento"
            disabled={loading}
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="email"
            label="Email"
            disabled={loading}
            fullWidth
            validate={[
              required("Este campo é obrigatório"),
              email("Invalid email"),
            ]}
          />
          <BooleanInput
            source="sign_cession"
            label="Assina termo de cessão?"
            disabled={loading}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancelar</Button>
          <Button type="submit" disabled={loading}>
            Salvar
            {loading && (
              <CircularProgress
                color="inherit"
                size={15}
                thickness={2}
                style={{ marginLeft: "10px" }}
              />
            )}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

RepresentativePopup.propTypes = {
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
};

export default RepresentativePopup;
