import { Typography } from "@mui/material";
import React from "react";
import {
  TextField,
  Show,
  SimpleShowLayout,
  DateField,
  ChipField,
  useRecordContext,
} from "react-admin";
import { BadgeColors } from "../../../utils/constants";

const UserDetailPage = (props) => (
  <Show>
    <UserDetail {...props} />
  </Show>
);

const UserDetail = (props) => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <SimpleShowLayout>
      <Typography variant="h5">Detalhes de Usuário</Typography>
      <TextField label="ID" source="id" />
      <TextField label="Nome" source="name" />
      <TextField label="E-mail" source="email" />
      <DateField
        source="created_at"
        label="Criado em"
        showTime
        locales="pt-BR"
      />
      <DateField
        source="updated_at"
        label="Última atualização"
        showTime
        locales="pt-BR"
      />
      <ChipField
        source="status_label"
        label="Status"
        size="small"
        color={BadgeColors[record.status] || "default"}
        sortable={false}
      />
    </SimpleShowLayout>
  );
};

export default UserDetailPage;
