import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";

import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  ReferenceField,
  TextField,
  useDataProvider,
  useNotify,
  useRefresh,
  ExportButton,
} from "react-admin";
import FilterSidebar from "../../../components/FilterSidebar";
import StyledList from "../../../components/StyledList";
import ConfirmationDialogHeader from "../../../components/ConfirmationDialogHeader";
import { useListContext } from "react-admin";

import Checkbox from "@mui/material/Checkbox";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";

import { BadgeColors } from "../../../utils/constants";
import { errorManageHandler } from "../../../utils/helpers";

const ConfirmActionModal = ({ isOpen, loading, onClose, onConfirm, label }) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogTitle>{ConfirmationDialogHeader(true, "Confirmação")}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Tem certeza que deseja registrar que esse processo está{" "}
        <strong>
          {label === "mark_as_sold"
            ? "com transferência confirmada"
            : "aguardando transferência"}
        </strong>
        ?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {!loading && (
        <Button variant="outlined" onClick={onClose}>
          Cancelar
        </Button>
      )}
      {loading ? (
        <CircularProgress size={25} thickness={3} style={{ margin: "20px" }} />
      ) : (
        <Button variant="contained" disableElevation onClick={onConfirm}>
          Confirmar
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

const AdvancementList = () => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const [isMarkAsSoldModalOpen, setMarkAsSoldModalOpen] = useState(false);
  const [isMarkAsProcessingModalOpen, setMarkAsProcessingModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");

  const handleToggleSelect = (id) => {
    if (!selectedIds.includes(id)) {
      setSelectedIds(selectedIds.concat(id));
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    }
  };

  const handleSelectMultiple = (ids) => {
    setSelectedIds(ids);
  };

  const handleAction = (action) => {
    setLoading(true);
    dataProvider
      .advancementsAction(action, selectedIds.join(","))
      .then(() => {
        setLoading(false);
        setMarkAsSoldModalOpen(false);
        setMarkAsProcessingModalOpen(false);
        refresh();
        notify(
          `notifications.advancements.approved${
            selectedIds.length > 1 ? "_many" : ""
          }`,
          { type: "success" }
        );
        setSelectedIds([]);
      })
      .catch((e) => {
        setLoading(false);
        const { messages } = errorManageHandler(e);
        messages.forEach((message) => {
          notify(message, { type: "error" });
        });
      });
  };

  return (
    <>
      <ConfirmActionModal
        isOpen={isMarkAsSoldModalOpen}
        loading={loading}
        onClose={() => setMarkAsSoldModalOpen(false)}
        onConfirm={() => handleAction("mark_as_sold")}
        label="mark_as_sold"
      />
      <ConfirmActionModal
        isOpen={isMarkAsProcessingModalOpen}
        loading={loading}
        onClose={() => setMarkAsProcessingModalOpen(false)}
        onConfirm={() => handleAction("mark_as_processing")}
        label="mark_as_processing"
      />
      <StyledList
        perPage={10}
        sort={{ field: "created_at", order: "DESC" }}
        filter={{ between_date_start: dateStart, between_date_end: dateEnd }}
        exporter={() => {
          dataProvider.exportAdvancements(selectedIds);
        }}
        aside={
          <FilterSidebar
            textFields={[
              { label: "ID", source: "ids" },
              { label: "Cedente", source: "supplier_name" },
            ]}
            statusList={[
              {
                label: "Aguardando Documentação",
                value: { status: "requested" },
              },
              {
                label: "Em Análise",
                value: { status: "pending" },
              },
              {
                label: "Aguardando Transferência",
                value: { status: "processing" },
              },
              {
                label: "Pagamento Realizado",
                value: { status: "sold" },
              },
              { label: "Cancelada", value: { status: "canceled" } },
            ]}
            setDateStart={setDateStart}
            setDateEnd={setDateEnd}
          />
        }
        actions={
          <div>
            <Button
              startIcon={<CheckRoundedIcon />}
              sx={{ color: "#2E7D32" }}
              onClick={() => {
                if (selectedIds.length === 0) {
                  notify(
                    "Selecione pelo menos uma operação para completar a ação",
                    {
                      type: "warning",
                    }
                  );
                  return;
                }
                setMarkAsSoldModalOpen(true);
              }}
            >
              Transferência Confirmada
            </Button>
            <Button
              startIcon={<MarkAsUnreadIcon />}
              sx={{ color: "#D32F2F", marginLeft: "8px" }}
              onClick={() => {
                if (selectedIds.length === 0) {
                  notify(
                    "Selecione pelo menos uma operação para completar a ação",
                    {
                      type: "warning",
                    }
                  );
                  return;
                }
                setMarkAsProcessingModalOpen(true);
              }}
            >
              Marcar como Aguardando Transferência
            </Button>
            <ExportButton sx={{ marginLeft: "20px" }} />
          </div>
        }
      >
        <AdvancementsDatagrid
          handleToggleSelect={handleToggleSelect}
          handleSelectMultiple={handleSelectMultiple}
          selectedIds={selectedIds}
        />
      </StyledList>
    </>
  );
};

const AdvancementsDatagrid = ({
  handleToggleSelect,
  handleSelectMultiple,
  selectedIds,
}) => {
  const { data, isLoading } = useListContext();
  if (isLoading) return <CircularProgress size={20} />;

  const handleSelectCurrentPage = (event) => {
    if (event.target.checked) {
      const pendingItems = data.filter((item) => item.status === "pending");
      const pendingIds = pendingItems.map((item) => item.id);
      handleSelectMultiple(pendingIds);
    } else {
      handleSelectMultiple([]);
    }
  };

  return (
    <Datagrid bulkActionButtons={false} size="medium" rowClick="edit">
      <FunctionField
        label={
          <Checkbox onChange={(event) => handleSelectCurrentPage(event)} />
        }
        render={(record) => (
          <Checkbox
            disabled={!["pending", "processing"].includes(record.status)}
            onChange={() => handleToggleSelect(record.id)}
            onClick={(event) => event.stopPropagation()}
            checked={selectedIds.includes(record.id)}
          />
        )}
      />
      <TextField source="id" label="ID" />
      <ReferenceField
        label="Cedente"
        source="supplier_id"
        reference="accounts"
        link={false}
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField
        source="created_at"
        label="Dt. Solicitação"
        showTime
        locales="pt-BR"
      />
      <DateField
        source="updated_at"
        label="Última atualização"
        showTime
        locales="pt-BR"
      />
      <FunctionField
        label="status"
        render={(record) => (
          <ChipField
            source="status_label"
            size="small"
            color={BadgeColors[record.status] || "default"}
            sortable={false}
          />
        )}
      />
    </Datagrid>
  );
};

export default AdvancementList;
