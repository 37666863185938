import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useDataProvider,
  useList,
  useShowContext,
} from "react-admin";

import { BadgeColors } from "../../../utils/constants";

const PartnerLenderList = ({ data }) => {
  const listContext = useList({ data });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false} size="medium">
        <ReferenceField
          label="Nome"
          source="id"
          reference="lenders"
          link="show"
        >
          <TextField source="name" label="Nome" sortable={false} />
        </ReferenceField>
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              sx={{ textTransform: "capitalize" }}
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
        <DateField
          source="created_at"
          label="Dt. Criação"
          showTime
          sortable={false}
          locales="pt-BR"
        />
        <DateField
          source="updated_at"
          label="Dt. Atualização"
          showTime
          sortable={false}
          locales="pt-BR"
        />
      </Datagrid>
    </ListContextProvider>
  );
};

const PartnerDetailContent = () => {
  const dataProvider = useDataProvider();
  const { record } = useShowContext();

  const [lenders, setLenders] = useState([]);
  const [loadingLenders, setLoadingLenders] = useState(false);
  const [errorLenders, setErrorLenders] = useState("");

  useEffect(() => {
    if (record) {
      setLoadingLenders(true);
      dataProvider
        .getPartnerLenderList(record.id)
        .then(({ data }) => {
          setLoadingLenders(false);
          setLenders(data);
        })
        .catch((error) => {
          setErrorLenders(error);
          setLoadingLenders(true);
        });
    }
  }, [record]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TabbedShowLayout>
      <Tab label="Resumo">
        <TextField label="ID" source="id" />
        <TextField label="Nome" source="name" />
        <TextField
          label="Tipo Documento"
          source="document_type"
          sx={{ textTransform: "uppercase" }}
        />
        <TextField label="Documento" source="document" />
        <DateField
          label="Criação"
          source="created_at"
          showTime
          locales="pt-BR"
        />
        <DateField
          label="Updated at"
          source="created_at"
          showTime
          locales="pt-BR"
        />
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
      </Tab>
      {loadingLenders ? (
        <Tab label="Fundos" path="lenders">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            my={5}
          >
            <CircularProgress />
          </Box>
        </Tab>
      ) : lenders ? (
        <Tab label="Fundos" path="lenders">
          <PartnerLenderList data={lenders} />
        </Tab>
      ) : errorLenders ? (
        <Tab label="Fundos" path="lenders">
          <Typography variant="p" textAlign="center" my={3}>
            Oops... Something went wrong, try again later
          </Typography>
        </Tab>
      ) : null}
    </TabbedShowLayout>
  );
};

const PartnerDetail = () => {
  return (
    <Show actions={null}>
      <PartnerDetailContent />
    </Show>
  );
};

export default PartnerDetail;
