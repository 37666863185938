import React from "react";

import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useList,
  useRecordContext,
} from "react-admin";
import { Typography } from "@mui/material";

import { BadgeColors } from "../../../utils/constants";
import TranslatedField from "../../../components/TranslatedField";
import NumberFieldWithTooltip from "../../../components/NumberFieldWithTooltip";
import AdditionalCostEntry from "../../../components/AdditionalCostEntry";

const SimulationDetailPage = () => (
  <Show>
    <SimulationDetail />
  </Show>
);

const SimulationDetail = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <SimpleShowLayout>
      <Typography variant="h5">Simulações</Typography>

      <TextField label="ID" source="id" />
      <ReferenceField
        label="Cedente"
        source="supplier_id"
        reference="accounts"
        link="show"
      >
        <TextField source="name" label="Cedente" sortable={false} />
      </ReferenceField>
      <ReferenceField
        label="Fundo"
        source="lender_id"
        reference="lenders"
        link="show"
      >
        <TextField source="name" label="Fundo" />
      </ReferenceField>
      <FunctionField
        label="Taxa"
        render={(record) => `${record.tax_perc_avg}% a.m.`}
      />
      <NumberField
        label="Valor Bruto"
        source="amount"
        locales="pt-BR"
        options={{ style: "currency", currency: "BRL" }}
      />
      <NumberField
        label="Valor Taxa"
        source="tax_amount"
        locales="pt-BR"
        options={{ style: "currency", currency: "BRL" }}
      />
      <NumberFieldWithTooltip
        label="Custos Adicionais"
        source="additional_costs_amount"
        tooltipDisabled={record.additional_costs.length === 0}
        locales="pt-BR"
        options={{ style: "currency", currency: "BRL" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          {record.additional_costs.map((cost, index) => (
            <AdditionalCostEntry cost={cost} key={index} />
          ))}
        </div>
      </NumberFieldWithTooltip>
      <NumberField
        label="Valor líquido"
        source="final_amount"
        locales="pt-BR"
        options={{ style: "currency", currency: "BRL" }}
      />
      <FunctionField
        label="status"
        render={(record) => (
          <ChipField
            source="status_label"
            size="small"
            color={BadgeColors[record.status] || "default"}
            sortable={false}
          />
        )}
      />
      <DateField
        label="Dt. Criação"
        source="created_at"
        showTime
        locales="pt-BR"
      />
      <DateField
        label="Dt. Atualização"
        source="updated_at"
        showTime
        locales="pt-BR"
      />

      <Typography variant="h6" mt={3}>
        Títulos
      </Typography>
      <FunctionField render={(record) => <AssetsList data={record.assets} />} />
    </SimpleShowLayout>
  );
};

const AssetsList = ({ data }) => {
  const listContext = useList({ data });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false} size="medium">
        <TextField
          label="NF/Contrato"
          source="invoice_number"
          sortable={false}
        />
        <FunctionField
          label="Parcela"
          render={(record) =>
            `${record.installment}/${record.installment_total}`
          }
        />
        <FunctionField
          label="Taxa"
          render={(record) => `${record.tax_perc}% a.m.`}
        />
        <NumberField label="Dias Antecipados" source="days_to_advance" />
        <NumberField
          label="Valor Bruto"
          source="amount"
          locales="pt-BR"
          options={{ style: "currency", currency: "BRL" }}
        />
        <NumberField
          label="Valor Taxa"
          source="tax_amount"
          locales="pt-BR"
          options={{ style: "currency", currency: "BRL" }}
        />
        <NumberField
          label="Valor Líquido"
          source="final_amount"
          locales="pt-BR"
          options={{ style: "currency", currency: "BRL" }}
        />
        <DateField
          source="expiration_date"
          label="Vencimento"
          sortable={false}
          locales="pt-BR"
        />
        <DateField
          source="expected_liquidation_date"
          label="Previsão de liquidação"
          sortable={false}
          locales="pt-BR"
        />
        <TranslatedField
          label="Forma de pagamento"
          source="sponsor_payment_method"
          sortable={false}
          prefix="payment_methods"
        />
      </Datagrid>
    </ListContextProvider>
  );
};

export default SimulationDetailPage;
