import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Card, Typography } from "@mui/material";
import {
  AutocompleteInput,
  maxValue,
  minValue,
  NumberInput,
  ReferenceInput,
  required,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useCreate,
  useAuthenticated,
} from "react-admin";

const LenderTaxRateCreate = () => {
  useAuthenticated();

  const navigate = useNavigate();
  const { id } = useParams();
  const notify = useNotify();
  const [create] = useCreate();

  const [loading, setLoading] = React.useState(false);

  const submitHandler = (data) => {
    setLoading(true);
    create(
      "lenders/sponsor_taxes",
      { data: { ...data, lender_id: id } },
      {
        onError: (error) => {
          navigate(`/lenders/${id}/show/tax`);
          notify(error.response.data.messages.join(", "), { type: "warning" });
        },
        onSuccess: () => {
          navigate(`/lenders/${id}/show/tax`);
        },
      }
    );
  };

  return (
    <Box p={3}>
      <Typography variant="h5" mb={2}>
        Nova taxa
      </Typography>
      <Card>
        <SimpleForm
          onSubmit={submitHandler}
          toolbar={
            <Toolbar>
              <SaveButton disabled={loading} />
            </Toolbar>
          }
        >
          <ReferenceInput
            label="Sacado"
            source="sponsor_id"
            reference="accounts"
            filter={{ type: "sponsor" }}
            enableGetChoices={({ q }) => q && q.length >= 2}
            validate={[required()]}
          >
            <AutocompleteInput
              label="Sacado"
              variant="outlined"
              optionText="name"
              sx={{ width: "195px" }}
            />
          </ReferenceInput>
          <NumberInput
            variant="outlined"
            label="Taxa"
            source="advancement_tax_rate"
            validate={[required(), minValue(0), maxValue(100)]}
          />
          <NumberInput
            variant="outlined"
            label="Taxa mínima"
            source="min_advancement_tax_rate"
            validate={[required(), minValue(0), maxValue(100)]}
          />
          <NumberInput
            variant="outlined"
            label="Taxa máxima"
            source="max_advancement_tax_rate"
            validate={[required(), minValue(0), maxValue(100)]}
          />
        </SimpleForm>
      </Card>
    </Box>
  );
};

export default LenderTaxRateCreate;
