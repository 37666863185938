import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Button as RAButton,
  Form,
  maxValue,
  minValue,
  NumberInput,
  required,
  useDataProvider,
  useListContext,
  useNotify,
} from "react-admin";

import { errorManageHandler } from "../../../../utils/helpers";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const CreateSimulatorButton = () => {
  const { selectedIds, onSelect } = useListContext();
  const navigate = useNavigate();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [openPopup, setOpenPopup] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    onSelect([]);
    return () => onSelect([]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const submitHandler = (data) => {
    const { advancement_tax_rate } = data;
    setLoading(true);
    dataProvider
      .create("simulations", {
        data: { advancement_tax_rate, payable_ids: selectedIds },
      })
      .then((res) => {
        const id = res?.data?.id;
        setLoading(false);
        navigate(id ? `/simulations/${id}/show` : "/simulations");
      })
      .catch((e) => {
        setLoading(false);
        const { messages } = errorManageHandler(e);
        messages.forEach((message) => {
          notify(message, { type: "error" });
        });
      });
  };

  return (
    <>
      <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
        <DialogTitle>Taxa para antecipação</DialogTitle>
        <Form onSubmit={submitHandler} noValidate>
          <DialogContent>
            <NumberInput
              variant="outlined"
              source="advancement_tax_rate"
              label="Taxa para antecipação"
              disabled={loading}
              fullWidth
              validate={[
                required("Este campo é obrigatório"),
                minValue(0),
                maxValue(100),
              ]}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenPopup(false)}>Cancel</Button>
            <Button type="submit" disabled={loading}>
              Submit
              {loading && (
                <CircularProgress
                  color="inherit"
                  size={15}
                  thickness={2}
                  style={{ marginLeft: "10px" }}
                />
              )}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      <RAButton label="Criar Simulação" onClick={() => setOpenPopup(true)} />
    </>
  );
};

export default CreateSimulatorButton;
