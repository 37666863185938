import React from "react";
import { useRecordContext } from "react-admin";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Tooltip } from "@mui/material";

const ActionField = (props) => {
  // Accepted types are:
  //  "button": renders a single button with props.title text which calls props.onClick
  //  "resolve": renders a pair of approve/reject buttons which call props.onApprove and props.onReject
  const { type } = props;

  const { title, onClick, onApprove, onReject, checkEnabled } = props;
  const record = useRecordContext(props);

  const actionsEnabled = checkEnabled(record);

  const handleClick = (callback) => (clickEvent) => {
    clickEvent.stopPropagation();
    callback(record);
  };

  if (type === "resolve") {
    return (
      <ResolveButtons
        disabled={!actionsEnabled}
        onApprove={handleClick(onApprove)}
        onReject={handleClick(onReject)}
      />
    );
  }

  return (
    <SingleButton
      title={title}
      disabled={!actionsEnabled}
      onClick={handleClick(onClick)}
    />
  );
};

const SingleButton = ({ title, disabled, onClick }) => (
  <Button variant="outlined" disabled={disabled} onClick={onClick}>
    {title}
  </Button>
);

const ResolveButtons = ({ disabled, onApprove, onReject }) => (
  <div style={{ display: "flex", flexDirection: "row" }}>
    <Tooltip title="Aprovar">
      <span>
        <IconButton
          variant="outlined"
          color="success"
          disabled={disabled}
          onClick={onApprove}
          sx={{ marginRight: "10px" }}
        >
          <CheckIcon />
        </IconButton>
      </span>
    </Tooltip>
    <Tooltip title="Reprovar">
      <span>
        <IconButton
          variant="outlined"
          color="error"
          disabled={disabled}
          onClick={onReject}
        >
          <CloseIcon />
        </IconButton>
      </span>
    </Tooltip>
  </div>
);

export default ActionField;
