import * as React from "react";

import { useDataProvider } from "react-admin";
import {
  Box,
  IconButton,
  Badge,
  Popper,
  Button,
  Tab,
  Divider,
  CircularProgress,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ClickAwayListener } from "@mui/base";
import { NotificationsRounded, CheckRounded } from "@mui/icons-material";
import { format, subDays } from "date-fns";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const dataProvider = useDataProvider();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tabValue, setTabValue] = React.useState("unread");
  const [notificationList, setNotificationList] = React.useState([]);
  const [unreadNotificationsCounter, setUnreadNotificationsCounter] =
    React.useState(0);
  const [statusToFilter, setStatusToFilter] = React.useState("sent,delivered");
  const [notificationData, setNotificationData] = React.useState({});
  const [pagination, setPagination] = React.useState({
    page: 0,
    total_pages: 1,
  });
  const [loading, setLoading] = React.useState(false);
  const open = Boolean(anchorEl);

  const checkNewNotifications = (notifications, notificationsData) => {
    const newNotifications = notifications.filter(
      (notification) => notification.status === "sent"
    );

    newNotifications.forEach((notification) => {
      const advancement = notificationsData[notification.args.advancement_id];

      const formattedAmount = Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(advancement.amount);

      const text = `${advancement.supplier_name} solicitou uma antecipação no valor de ${formattedAmount}`;

      new Notification("Nova antecipação!", { body: text }).onclick = () => {
        handleNotificationClick(
          "advancements",
          advancement.id,
          notification.id,
          true
        );
        window.focus();
      };
    });
  };

  const getAdvancementDataFromNotifications = (notifications) => {
    const advancementRequestedIds = notifications
      .filter((notification) => notification.type === "advancement_requested")
      .map((notification) => notification.args.advancement_id);

    if (advancementRequestedIds.length > 0) {
      return new Promise((resolve) => {
        dataProvider
          .getMany("advancements", {
            ids: advancementRequestedIds,
          })
          .then(({ data }) => {
            const notificationDataMap = { ...notificationData };
            data.forEach((advancement) => {
              notificationDataMap[advancement.id] = advancement;
            });
            setNotificationData(notificationDataMap);
            resolve(notificationDataMap);
          })
          .catch((e) => {
            console.log(e.message);
          });
      });
    }
    return Promise.resolve([]);
  };

  const getNotifications = (checkingForNewNotifications) => {
    const { page } = pagination;

    setLoading(true);

    dataProvider
      .getNotifications({
        pagination: {
          page: checkingForNewNotifications ? 1 : page + 1,
          perPage: 10,
        },
        filter: {
          status: checkingForNewNotifications
            ? "sent,delivered"
            : statusToFilter,
        },
      })
      .then(({ data, pagination, total }) => {
        if (!checkingForNewNotifications) {
          setNotificationList((prevList) => {
            return [...prevList, ...data];
          });
          setPagination(pagination);
        }

        if (statusToFilter !== "read" || checkingForNewNotifications) {
          setUnreadNotificationsCounter(total);
        }

        getAdvancementDataFromNotifications(data).then((advancementData) => {
          checkNewNotifications(data, advancementData);
        });
      })
      .catch((e) => {
        console.log(e.message);
      })
      .finally(() => setLoading(false));
  };

  const handleCheckForNewNotifications = () => {
    const checkingForNewNotifications = true;
    getNotifications(checkingForNewNotifications);
  };

  React.useEffect(() => {
    getNotifications();

    const timer = setInterval(handleCheckForNewNotifications, 30000);

    return () => clearInterval(timer);
  }, [statusToFilter, open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (event) => {
    setPagination({});
    setNotificationList([]);
    setAnchorEl(anchorEl ? null : event.currentTarget);

    Notification.permission === "default" &&
      alert(
        "Por favor, permita as notificações para receber alertas sobre novas antecipações."
      );

    Notification.requestPermission();
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === "all") setStatusToFilter("read");
    else setStatusToFilter("sent,delivered");

    setNotificationList([]);
    setPagination({});
  };

  const handleMarkAsRead = (notificationId) => {
    dataProvider
      .markNotificationAsRead(notificationId)
      .then(() => {
        if (notificationId) {
          setNotificationList((prevList) => {
            return prevList.filter(
              (notification) => notification.id !== notificationId
            );
          });
          setUnreadNotificationsCounter(unreadNotificationsCounter - 1);
        } else {
          setNotificationList([]);
          setUnreadNotificationsCounter(0);
        }
      })
      .catch((e) => console.log(e.message));
  };

  const handleNotificationClick = (
    resource,
    resourceId,
    notificationId,
    markAsRead
  ) => {
    markAsRead && handleMarkAsRead(notificationId);

    const url = `/${resource}/${resourceId}`;
    navigate(url);
    setAnchorEl(null);
  };

  const handleOnScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const { page, total_pages } = pagination;

    // Handle page zoom
    const zoomLevel = window.visualViewport.scale || 1;
    const visibleHeight = clientHeight / zoomLevel;
    const scrolledHeight = scrollTop + visibleHeight;

    if (
      scrollHeight - scrolledHeight <= 1 &&
      (!page || page < total_pages) &&
      !loading
    )
      getNotifications();
  };

  const handleBadgeContent = () => {
    if (unreadNotificationsCounter > 0) return unreadNotificationsCounter;
    if (Notification.permission === "default") return "!";
    return null;
  };

  return (
    <>
      <IconButton sx={{ margin: "0px 12px 0px 4px" }} onClick={handleClick}>
        <Badge
          badgeContent={handleBadgeContent()}
          overlap="circular"
          color="warning"
        >
          <NotificationsRounded sx={{ color: "#fff" }} />
        </Badge>
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorEl}
        disablePortal
        sx={{
          zIndex: "9",
          background: "#fff",
          borderRadius: "10px",
          width: "400px",
          color: "black",
          boxShadow:
            "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
        }}
      >
        <ClickAwayListener onClickAway={handleClick}>
          <Box>
            <Box sx={{ padding: "16px 22px" }}>
              <b>Notificações</b>
              <Button
                startIcon={<CheckRounded />}
                sx={{
                  float: "right",
                  padding: "0px",
                  textTransform: "none",
                }}
                disabled={
                  unreadNotificationsCounter === 0 || statusToFilter === "read"
                }
                onClick={() => handleMarkAsRead()}
              >
                Marcar como lida
              </Button>
            </Box>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleTabChange}>
                  <Tab
                    label={
                      <div>
                        {unreadNotificationsCounter > 0 && (
                          <div
                            style={{
                              float: "right",
                              margin: "-2px 10px 0px 16px",
                            }}
                          >
                            <Badge
                              badgeContent={unreadNotificationsCounter}
                              color="primary"
                            ></Badge>
                          </div>
                        )}
                        <b>NÃO LIDAS</b>
                      </div>
                    }
                    value="unread"
                  />
                  <Tab label="VER TODAS" value="all" />
                </TabList>
              </Box>
              <TabPanel
                value="unread"
                sx={tabPanelStyle}
                onScroll={handleOnScroll}
              >
                <NotificationTab
                  tabValue={tabValue}
                  loading={loading}
                  notificationList={notificationList}
                  notificationData={notificationData}
                  handleNotificationClick={handleNotificationClick}
                ></NotificationTab>
              </TabPanel>
              <TabPanel
                value="all"
                sx={tabPanelStyle}
                onScroll={handleOnScroll}
              >
                <NotificationTab
                  tabValue={tabValue}
                  loading={loading}
                  notificationList={notificationList}
                  notificationData={notificationData}
                  handleNotificationClick={handleNotificationClick}
                ></NotificationTab>
              </TabPanel>
            </TabContext>
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

const NotificationTab = ({
  tabValue,
  loading,
  notificationList,
  notificationData,
  handleNotificationClick,
}) => {
  return (
    <React.Fragment>
      <table style={{ width: "100%" }}>
        <tbody>
          {notificationList.length ? (
            notificationList.map((notification, index) => {
              const advancementId = notification.args.advancement_id;

              if (
                notification.type === "advancement_requested" &&
                notificationData[advancementId]
              ) {
                const advancement = notificationData[advancementId];

                const notificationTime = new Date(notification.created_at);

                const { formattedTime, formattedDate, dateDividerText } =
                  formatNotificationTime(notificationTime);

                const prevNotificationDate =
                  index > 0
                    ? format(
                        new Date(notificationList[index - 1].created_at),
                        "dd/MM/yyyy"
                      )
                    : null;

                const checkDate =
                  dateDividerText && formattedDate !== prevNotificationDate;

                return (
                  <tr key={index}>
                    {tabValue === "unread" && (
                      <td
                        style={{
                          verticalAlign: "top",
                          paddingTop: `${checkDate ? "56px" : "28px"}`,
                        }}
                      >
                        <Badge badgeContent={1} color="primary" variant="dot" />
                      </td>
                    )}
                    <td>
                      {checkDate && (
                        <Box
                          sx={{
                            fontSize: "14px",
                            paddingBottom: "6px",
                          }}
                        >
                          <Divider
                            sx={{
                              width: "120%",
                              marginTop: "-4px",
                              marginBottom: "4px",
                              translate: "-10%",
                            }}
                          />
                          <div
                            style={{
                              paddingLeft: `${
                                tabValue === "unread" ? "40px" : "0px"
                              }`,
                            }}
                          >
                            {dateDividerText}
                          </div>
                        </Box>
                      )}
                      <Divider
                        sx={{
                          width: "120%",
                          marginTop: "-4px",
                          translate: "-10%",
                        }}
                      />
                      <Box
                        sx={{
                          padding: `18px 30px 0px ${
                            tabValue === "unread" ? "40px" : "0px"
                          }`,
                          lineHeight: "1.5",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleNotificationClick(
                            "advancements",
                            advancement.id,
                            notification.id,
                            tabValue === "unread"
                          )
                        }
                      >
                        <b>{advancement.supplier_name}</b> solicitou uma
                        antecipação no valor de{" "}
                        <Box
                          sx={{
                            color: "primary.main",
                            fontWeight: "bold",
                            display: "inline",
                          }}
                        >
                          {Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(advancement.amount)}
                        </Box>
                        <Box
                          sx={{
                            fontSize: "14px",
                            color: "dimgray",
                            marginTop: "-4px",
                          }}
                        >
                          <p>{formattedTime}</p>
                        </Box>
                      </Box>
                    </td>
                  </tr>
                );
              }
              return null;
            })
          ) : (
            <tr>
              <td>
                <Box
                  textAlign={"center"}
                  paddingY={"3rem"}
                  sx={{ color: "text.secondary" }}
                >
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <div>
                      Sem {tabValue === "unread" && "novas"} notificações
                    </div>
                  )}
                </Box>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
};

const formatNotificationTime = (notificationTime) => {
  const currentDate = new Date();

  const timeDifference = Math.abs(currentDate - notificationTime);
  const formattedDate = format(notificationTime, "dd/MM/yyyy");
  let formattedTime = format(notificationTime, "HH:mm");
  let dateDividerText;

  if (timeDifference < 60000) {
    // Menos de 1 minuto atrás
    formattedTime = "Agora mesmo";
  } else if (timeDifference < 3660000) {
    // Menos de 1 hora atrás
    const minutesAgo = Math.round(timeDifference / 60000);
    formattedTime = `${minutesAgo} minutos atrás`;

    minutesAgo === 1 && (formattedTime = "1 minuto atrás");

    minutesAgo === 60 && (formattedTime = "1 hora atrás");
  }

  if (formattedDate === format(subDays(currentDate, 1), "dd/MM/yyyy")) {
    dateDividerText = "Ontem";
  } else if (formattedDate !== format(currentDate, "dd/MM/yyyy")) {
    dateDividerText = formattedDate;
  }

  return { formattedTime, formattedDate, dateDividerText };
};

const tabPanelStyle = {
  paddingY: 0,
  maxHeight: "70vh",
  overflowX: "hidden",
  overflowY: "auto",
  scrollbarWidth: "thin",
  "&::-webkit-scrollbar": {
    width: "7px",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "4px",
    backgroundColor: "primary.main",
  },
};

export default Notifications;
