import React from "react";
import { FunctionField, useTranslate } from "react-admin";

const TranslatedField = (props) => {
  const translate = useTranslate();

  return (
    <FunctionField
      render={(record, source) =>
        record?.[source]
          ? translate(`${props.prefix}.${record?.[source]}`)
          : props.emptyText
      }
      {...props}
    />
  );
};

export default TranslatedField;
