export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const ACCOUNT_BASE_URL = process.env.REACT_APP_ACCOUNT_API_BASE_URL;

/**
 * Add more status color here based on our status text.
 * For example, in simulation status, we have simulated, then you can add it like:
 * simulated: 'info',
 * Possible chip color options are "default", "primary", "secondary", "error", "info", "success", "warning"
 */
export const BadgeColors = {
  active: "success",
  success: "success",
  pending: "secondary",
  simulated: "success",
  proceeded: "secondary",
  processed: "secondary",
  available: "success",
  liquidated: "success",
  sold: "success",
  error: "error",
  failure: "error",
  canceled: "info",
  waiting: "warning",
  analyzing: "info",
  processing: "info",

  201: "success",
  400: "error",
  401: "error",
  403: "error",
  500: "error",
};
