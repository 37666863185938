import React, { useState } from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  email,
  Form,
  required,
  TextInput,
  useDataProvider,
  useNotify,
  useRefresh,
} from "react-admin";
import { errorManageHandler } from "../../../../utils/helpers";

const UserPopup = ({ accountId, open, setOpen }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    dataProvider
      .create(`accounts/${accountId}/users`, {
        data: {
          name: data.user_name,
          email: data.email,
        },
      })
      .then(() => {
        setOpen(false);
        refresh();
      })
      .catch((e) => {
        const { messages } = errorManageHandler(e);
        messages.forEach((message) => {
          notify(message, { type: "error" });
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Cadastrar usuário</DialogTitle>
      <Form onSubmit={onSubmit} noValidate>
        <DialogContent>
          <TextInput
            variant="outlined"
            source="user_name"
            label="Nome do usuário"
            disabled={loading}
            fullWidth
            defaultValue=""
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="email"
            label="Email"
            disabled={loading}
            fullWidth
            validate={[
              required("Este campo é obrigatório"),
              email("Email inválido"),
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancelar</Button>
          <Button type="submit" disabled={loading}>
            Salvar
            {loading && (
              <CircularProgress
                color="inherit"
                size={15}
                thickness={2}
                style={{ marginLeft: "10px" }}
              />
            )}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default UserPopup;
