import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";
import {
  Button as RaButton,
  ChipField,
  DateField,
  FunctionField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { Add } from "@mui/icons-material";

import RegistrationData from "./Components/RegistrationData";
import UserList from "./Components/UserList";
import ShareHolderList from "./Components/ShareHolderList";
import ShareHolderPopup from "./Components/ShareHolderPopup";
import RepresentativeList from "./Components/RepresentativeList";
import RepresentativePopup from "./Components/RepresentativePopup";
import BankAccountList from "./Components/BankAccountList";
import BankAccountPopup from "./Components/BankAccountPopup";
import ContactList from "./Components/ContactList";
import ContactPopup from "./Components/ContactPopup";
import AddressList from "./Components/AddressList";
import AddressPopup from "./Components/AddressPopup";
import SupplierPayableList from "./Components/SupplierPayableList";
import SponsorPayableList from "./Components/SponsorPayableList";
import SimulationList from "./Components/SimulationList";
import SponsorSettings from "./Components/SponsorSettings";

import { errorManageHandler } from "../../../utils/helpers";
import { BadgeColors } from "../../../utils/constants";
import UserPopup from "./Components/UserPopup";

const AccountDetailPage = () => (
  <Show>
    <AccountDetail />
  </Show>
);

const AccountDetail = () => {
  const { id } = useParams();
  const account = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  // After clicking on the "Buscar Informações" button, a series of popups will open
  // to save registration, shareholder, representative and contact data found.
  // Each item in the popupQueue is an object with the following structure:
  // {  popup: "registration" | "shareholder" | "address"
  //    data: { ... } }
  const [popupQueue, setPopupQueue] = useState([]);
  const [shouldOpenNextPopup, setShouldOpenNextPopup] = useState(false);

  const [openUserPopup, setOpenUserPopup] = useState(false);
  const [openRegistrationPopup, setOpenRegistrationPopup] = useState(false);
  const [openShareHolderPopup, setOpenShareHolderPopup] = useState(false);
  const [openRepresentativePopup, setOpenRepresentativePopup] = useState(false);
  const [openBankAccountPopup, setOpenBankAccountPopup] = useState(false);
  const [openContactPopup, setOpenContactPopup] = useState(false);
  const [openAddressPopup, setOpenAddressPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFetchingCNPJInfo, setFetchingCNPJInfo] = useState(false);
  const [cnpjErrorMessage, setCNPJErrorMessage] = useState("");
  const [cnpjRegistrationInfo, setCNPJRegistrationInfo] = useState(null);
  const [cnpjShareholderInfo, setCNPJShareholderInfo] = useState(null);
  const [cnpjAddressInfo, setCNPJAddressInfo] = useState(null);

  const openNextPopup = () => {
    if (popupQueue.length === 0) return;

    const openPopupFunc = {
      shareholder: () => setOpenShareHolderPopup(true),
      registration: () => setOpenRegistrationPopup(true),
      address: () => setOpenAddressPopup(true),
    }[popupQueue[0]["popup"]];

    const setInfo = {
      registration: () => setCNPJRegistrationInfo(popupQueue[0]["data"]),
      shareholder: () => setCNPJShareholderInfo(popupQueue[0]["data"]),
      address: () => setCNPJAddressInfo(popupQueue[0]["data"]),
    }[popupQueue[0]["popup"]];

    openPopupFunc();
    setInfo();
    setPopupQueue(popupQueue.slice(1));
    setShouldOpenNextPopup(false);
  };

  useEffect(() => {
    if (shouldOpenNextPopup) openNextPopup();
  }, [shouldOpenNextPopup]);

  const submitShareHolderHandler = (data) => {
    setLoading(true);
    dataProvider
      .create(`accounts/${id}/shareholders`, {
        data: {
          account_id: id,
          email: data.email,
          name: data.sh_name,
          document_type: data.sh_document_type,
          document: data.sh_document,
          share: data.share,
        },
      })
      .then((res) => {
        setLoading(false);
        setOpenShareHolderPopup(false);
        setShouldOpenNextPopup(true);
        notify("Sócio cadastrado", { type: "success" });
        refresh();
      })
      .catch((e) => {
        setLoading(false);
        const { messages } = errorManageHandler(e);
        messages.forEach((message) => {
          notify(message, { type: "error" });
        });
      });
  };

  const submitRepresentativeHandler = (data) => {
    setLoading(true);
    dataProvider
      .create(`accounts/${id}/representatives`, {
        data: {
          account_id: id,
          email: data.email,
          name: data.r_name,
          document_type: data.r_document_type,
          document: data.r_document,
          sign_cession: data.sign_cession,
        },
      })
      .then((res) => {
        setLoading(false);
        setOpenRepresentativePopup(false);
        notify("Representante cadastrado", { type: "success" });
        refresh();
      })
      .catch((e) => {
        setLoading(false);
        const { messages } = errorManageHandler(e);
        messages.forEach((message) => {
          notify(message, { type: "error" });
        });
      });
  };

  const submitBankAccountHandler = (data) => {
    setLoading(true);
    dataProvider
      .create(`accounts/${id}/bank_accounts`, {
        data: {
          account_digit: data.ba_account_digit,
          account_number: data.ba_account_number,
          account_type: data.ba_account_type,
          bank_agency: data.ba_bank_agency,
          bank_code: data.ba_bank_code,
        },
      })
      .then((res) => {
        setLoading(false);
        setOpenBankAccountPopup(false);
        notify("Conta bancária criada", { type: "success" });
        refresh();
      })
      .catch((e) => {
        setLoading(false);
        const { messages } = errorManageHandler(e);
        messages.forEach((message) => {
          notify(message, { type: "error" });
        });
      });
  };

  const submitContactHandler = (data) => {
    setLoading(true);
    dataProvider
      .create(`accounts/${id}/contacts`, {
        data: {
          account_id: id,
          email: data.email,
          name: data.c_name,
          phone_number: data.phone_number,
          position: data.position,
        },
      })
      .then((res) => {
        setLoading(false);
        setOpenContactPopup(false);
        notify("Contato cadastrado", { type: "success" });
        refresh();
      })
      .catch((e) => {
        setLoading(false);
        const { messages } = errorManageHandler(e);
        messages.forEach((message) => {
          notify(message, { type: "error" });
        });
      });
  };

  const submitAddressHandler = (data) => {
    setLoading(true);
    dataProvider
      .create(`accounts/${id}/addresses`, { data: { ...data, account_id: id } })
      .then((res) => {
        setLoading(false);
        setOpenAddressPopup(false);
        setShouldOpenNextPopup(true);
        notify("Endereço adicionado", { type: "success" });
        refresh();
      })
      .catch((e) => {
        setLoading(false);
        const { messages } = errorManageHandler(e);
        messages.forEach((message) => {
          notify(message, { type: "error" });
        });
      });
  };

  const handleFetchCNPJInfo = async () => {
    const cnpj = account.document.replace(/[./-]/g, "");
    if (!cnpj || cnpj.length !== 14) {
      setCNPJErrorMessage("Só é possível buscar informações de CNPJs válidos.");
      return;
    }

    setFetchingCNPJInfo(true);
    setCNPJErrorMessage("");
    try {
      const cnpjResponse = await dataProvider.getInfoFromCNPJ(cnpj);

      if (cnpjResponse.status === 200) {
        setPopupQueue([
          { popup: "registration", data: cnpjResponse.data },
          ...cnpjResponse.data.socios.map((shareholderInfo) => ({
            popup: "shareholder",
            data: shareholderInfo,
          })),
          { popup: "address", data: cnpjResponse.data.estabelecimento },
        ]);
        setShouldOpenNextPopup(true);
      }
    } catch (error) {
      if (error?.response?.status === 429) {
        setCNPJErrorMessage(
          "O limite de requisições por minuto foi atingido. Aguarde um momento e tente novamente."
        );
      } else {
        setCNPJErrorMessage(
          "Erro ao buscar informações. Tente novamente mais tarde."
        );
      }
    }

    setFetchingCNPJInfo(false);
  };

  return (
    <TabbedShowLayout>
      <Tab label="Detalhe da Conta">
        <TextField label="ID" source="id" />
        <TextField label="Nome" source="name" />
        <TextField label="Nome Fantasia" source="trade_name" />
        <TextField label="Telefone" source="phone_number" />
        <TextField label="Tipo" source="document_type" />
        <FunctionField
          label="Documento"
          source="document"
          render={(record) =>
            record.document?.length === 14
              ? record.document.replace(
                  /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                  "$1.$2.$3/$4-$5"
                )
              : record.document
          }
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <RaButton
            variant="outlined"
            disabled={loading || isFetchingCNPJInfo || !!cnpjErrorMessage}
            label="Buscar Informações"
            onClick={handleFetchCNPJInfo}
            sx={{ maxWidth: "fit-content" }}
          />
          {cnpjErrorMessage && (
            <span
              style={{
                margin: "4px 12px",
                fontSize: "12px",
                color: "#ef5350",
              }}
            >
              {cnpjErrorMessage}
            </span>
          )}
        </Box>
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              sx={{ width: "fit-content" }}
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
        <DateField
          label="Dt. Criação"
          source="created_at"
          showTime
          locales="pt-BR"
        />
        <DateField
          label="Dt. Atualização"
          source="updated_at"
          showTime
          locales="pt-BR"
        />

        <RegistrationData
          open={openRegistrationPopup}
          onOpen={() => setOpenRegistrationPopup(true)}
          onClose={() => {
            setOpenRegistrationPopup(false);
            openNextPopup();
          }}
          accountId={id}
          cnpjInfo={cnpjRegistrationInfo}
        />

        {account?.type === "supplier" && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              mt={3}
              alignItems="flex-end"
            >
              <Typography variant="h5">Usuários</Typography>
              <Button
                variant="text"
                size="small"
                startIcon={<Add />}
                onClick={() => setOpenUserPopup(true)}
              >
                Cadastrar usuário
              </Button>
            </Box>
            <UserList accountId={id} />
            <UserPopup
              accountId={id}
              open={openUserPopup}
              setOpen={setOpenUserPopup}
            />
          </>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          mt={3}
          alignItems="flex-end"
        >
          <Typography variant="h5">Quadro Societário</Typography>
          <Button
            variant="text"
            size="small"
            startIcon={<Add />}
            onClick={() => setOpenShareHolderPopup(true)}
          >
            Cadastrar sócio
          </Button>
        </Box>
        <ShareHolderPopup
          cnpjInfo={cnpjShareholderInfo}
          loading={loading}
          open={openShareHolderPopup}
          onClose={() => {
            setOpenShareHolderPopup(false);
            openNextPopup();
          }}
          submitHandler={submitShareHolderHandler}
        />
        <ShareHolderList accountId={id} />

        <Box
          display="flex"
          justifyContent="space-between"
          mt={3}
          alignItems="flex-end"
        >
          <Typography variant="h5">Representantes Legais</Typography>
          <Button
            variant="text"
            size="small"
            startIcon={<Add />}
            onClick={() => setOpenRepresentativePopup(true)}
          >
            Novo representante
          </Button>
        </Box>
        <RepresentativePopup
          loading={loading}
          open={openRepresentativePopup}
          setOpen={setOpenRepresentativePopup}
          submitHandler={submitRepresentativeHandler}
        />
        <RepresentativeList accountId={id} />

        <Box
          display="flex"
          justifyContent="space-between"
          mt={3}
          alignItems="flex-end"
        >
          <Typography variant="h5">Contas Bancárias</Typography>
          <Button
            variant="text"
            size="small"
            startIcon={<Add />}
            onClick={() => setOpenBankAccountPopup(true)}
          >
            Nova conta bancária
          </Button>
        </Box>
        <BankAccountPopup
          loading={loading}
          open={openBankAccountPopup}
          setOpen={setOpenBankAccountPopup}
          submitHandler={submitBankAccountHandler}
        />
        <BankAccountList accountId={id} />

        <Box
          display="flex"
          justifyContent="space-between"
          mt={3}
          alignItems="flex-end"
        >
          <Typography variant="h5">Lista de Contatos</Typography>
          <Button
            variant="text"
            size="small"
            startIcon={<Add />}
            onClick={() => setOpenContactPopup(true)}
          >
            Cadastrar contato
          </Button>
        </Box>
        <ContactList accountId={id} />
        <ContactPopup
          loading={loading}
          open={openContactPopup}
          setOpen={setOpenContactPopup}
          submitHandler={submitContactHandler}
        />

        <Box
          display="flex"
          justifyContent="space-between"
          mt={3}
          alignItems="flex-end"
        >
          <Typography variant="h5">Endereços</Typography>
          <Button
            variant="text"
            size="small"
            startIcon={<Add />}
            onClick={() => setOpenAddressPopup(true)}
          >
            Novo endereço
          </Button>
        </Box>
        <AddressPopup
          cnpjInfo={cnpjAddressInfo}
          loading={loading}
          open={openAddressPopup}
          onClose={() => {
            setOpenAddressPopup(false);
            openNextPopup();
          }}
          submitHandler={submitAddressHandler}
        />
        <AddressList accountId={id} />
      </Tab>
      <Tab label="Títulos a pagar">
        <SponsorPayableList sponsorId={id} />
      </Tab>
      <Tab label="Títulos a receber">
        <SupplierPayableList supplierId={id} />
      </Tab>
      <Tab label="Simulações">
        <SimulationList supplierId={id} />
      </Tab>
      {account?.type === "sponsor" && (
        <Tab label="Configurações">
          <SponsorSettings />
        </Tab>
      )}
    </TabbedShowLayout>
  );
};

export default AccountDetailPage;
