import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { useLogin, useNotify } from "react-admin";
import { Box, Card, CircularProgress, Typography } from "@mui/material";

import AuthLayout from "../../../layouts/AuthLayout/AuthLayout";

import { errorManageHandler } from "../../../utils/helpers";

const LoginCallback = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const login = useLogin();
  const notify = useNotify();

  useEffect(() => {
    const code = searchParams.get("code");
    if (!code || code === "") {
      // if no authorization code, redirect to login page to start
      // a new oauth2 login flow
      navigate("/login");
      return;
    }

    console.log("Authorization code is ", code);
    // calling the login function from the authProvider, informing the authorization_code
    login({ data: { code }, isFallback: false }, "/").catch((e) => {
      const { messages } = errorManageHandler(e);
      messages.forEach((message) => notify(message, { type: "error" }));
      navigate("/login");
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthLayout>
      <FormCard>
        <Typography variant="h6">Redirecting...</Typography>
        <Box style={{ marginTop: "15px" }}>
          <CircularProgress size={40} thickness={5} />
        </Box>
      </FormCard>
    </AuthLayout>
  );
};

const FormCard = styled(Card)`
  width: 300px;
  padding: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default LoginCallback;
