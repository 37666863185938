import React, { useEffect, useState } from "react";
import {
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";

import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { errorManageHandler } from "../../../../utils/helpers";

// TODO: get available methods from server?
const availablePaymentMethods = [
  { id: "boleto", name: "Boleto" },
  { id: "ted", name: "TED" },
];

const defaultPaymentMethod = "ted";

const SponsorSettings = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const sponsor = useRecordContext();

  const [formState, setFormState] = useState({
    liquidation_payment_method: defaultPaymentMethod,
  });
  const [isLoadingSettings, setLoadingSettings] = useState(true);
  const [isSaveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    dataProvider
      .getResource([["accounts", sponsor.id], ["sponsor_settings"]])
      .then((response) => {
        setFormState({
          liquidation_payment_method: response.data.liquidation_payment_method,
        });
      })
      .catch(() => {
        setFormState({ liquidation_payment_method: defaultPaymentMethod });
      })
      .finally(() => {
        setLoadingSettings(false);
      });
  }, [dataProvider, sponsor.id]);

  const handleChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const handleSubmit = () => {
    setSaveLoading(true);
    dataProvider
      .updateResource(
        [["accounts", sponsor.id], ["sponsor_settings"]],
        formState
      )
      .then(() => notify("Configurações salvas com sucesso!"))
      .catch((e) => {
        const { messages } = errorManageHandler(e);
        messages.forEach((msg) => notify(msg, { type: "error" }));
      })
      .finally(() => {
        refresh();
        setSaveLoading(false);
      });
  };

  if (!sponsor) return null;

  const mainContent = (
    <>
      <FormControl onSubmit={handleSubmit}>
        <InputLabel id="payment-method-label">Forma de Pagamento</InputLabel>
        <Select
          label="Forma de pagamento"
          labelId="payment-method-label"
          name="liquidation_payment_method"
          value={formState.liquidation_payment_method}
          onChange={handleChange}
        >
          {availablePaymentMethods.map((choice) => (
            <MenuItem key={choice.id} value={choice.id}>
              {choice.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isSaveLoading ? (
        <CircularProgress
          size={36.5}
          thickness={3}
          sx={{ marginTop: "20px" }}
        />
      ) : (
        <Button
          variant="contained"
          disableElevation
          onClick={handleSubmit}
          sx={{ width: "100px", marginTop: "20px" }}
        >
          Salvar
        </Button>
      )}
    </>
  );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h5" sx={{ marginBottom: "20px" }}>
        {sponsor.name}
      </Typography>

      {isLoadingSettings ? (
        <CircularProgress
          size={36.5}
          thickness={3}
          sx={{ marginTop: "20px" }}
        />
      ) : (
        mainContent
      )}
    </div>
  );
};

export default SponsorSettings;
