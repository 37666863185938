import React from "react";
import PropTypes from "prop-types";

import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
} from "react-admin";

import { BadgeColors } from "../../../../utils/constants";

const SimulationList = ({ supplierId }) => {
  return (
    <List
      disableSyncWithLocation
      filter={{ supplier_id: supplierId, status: "" }}
      resource="simulations"
      perPage={25}
      actions={false}
    >
      <Datagrid bulkActionButtons={false} size="medium" rowClick="show">
        <DateField
          source="created_at"
          label="Dt. Criação"
          showTime
          sortable={false}
          locales="pt-BR"
        />
        <FunctionField
          label="Taxa"
          render={(record) => `${record.tax_perc_avg}% a.m.`}
        />
        <NumberField
          label="Valor Bruto"
          source="amount"
          options={{ style: "currency", currency: "BRL" }}
        />
        <NumberField
          label="Valor Taxa"
          source="tax_amount"
          options={{ style: "currency", currency: "BRL" }}
        />
        <NumberField
          label="Valor líquido"
          source="final_amount"
          options={{ style: "currency", currency: "BRL" }}
        />
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

SimulationList.propTypes = {
  supplierId: PropTypes.string.isRequired,
};

export default SimulationList;
