import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { Add, Edit } from "@mui/icons-material";

import {
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  ListContextProvider,
  NumberField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useDataProvider,
  useList,
  useShowContext,
} from "react-admin";

import { BadgeColors } from "../../../utils/constants";
import TranslatedField from "../../../components/TranslatedField";

const LenderBankAccountList = ({ data }) => {
  const listContext = useList({ data });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false} size="medium">
        <TextField source="id" label="ID" sortable={false} />
        <TextField source="bank_code" label="Banco" sortable={false} />
        <TextField source="bank_agency" label="Agência" sortable={false} />
        <TextField
          source="bank_account_number"
          label="Número da Conta"
          sortable={false}
        />
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
      </Datagrid>
    </ListContextProvider>
  );
};

const LenderTaxList = ({ data, lenderId }) => {
  const navigate = useNavigate();
  const listContext = useList({ data });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false} size="medium">
        <TextField source="id" label="ID" sortable={false} />
        <ReferenceField
          label="Sacado"
          source="sponsor_id"
          reference="accounts"
          link={false}
        >
          <FunctionField
            label="Sacado"
            render={(record) => `(${record.document}) ${record.name}`}
          />
        </ReferenceField>
        <FunctionField
          label="Taxa de Antecipação"
          render={(record) => `${record.advancement_tax_rate}% a.m.`}
        />
        <FunctionField
          label="Taxa máxima"
          render={(record) => `${record.max_advancement_tax_rate}% a.m.`}
        />
        <FunctionField
          label="Taxa mínima"
          render={(record) => `${record.min_advancement_tax_rate}% a.m.`}
        />
        <DateField
          source="created_at"
          label="Dt. Criação"
          showTime
          sortable={false}
          locales="pt-BR"
        />
        <DateField
          source="updated_at"
          label="Dt. Atualização"
          showTime
          sortable={false}
          locales="pt-BR"
        />
        <FunctionField
          label=""
          render={(record) => (
            <Button
              variant="text"
              size="small"
              startIcon={<Edit />}
              onClick={() =>
                navigate(`/lenders/${lenderId}/tax/${record.id}/edit`)
              }
            >
              Alterar
            </Button>
          )}
        />
      </Datagrid>
    </ListContextProvider>
  );
};

const LenderDetailContent = () => {
  const dataProvider = useDataProvider();
  const { record } = useShowContext();

  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [bankAccounts, setBankAccounts] = useState([]);
  const [loadingBankAccount, setLoadingBankAccount] = useState(false);
  const [errorBankAccount, setErrorBankAccount] = useState("");

  const [taxes, setTaxes] = useState([]);
  const [loadingTax, setLoadingTax] = useState(false);
  const [errorTax, setErrorTax] = useState("");

  useEffect(() => {
    if (record) {
      setLoading(true);
      dataProvider
        .getLenderConfig(record.id)
        .then(({ data }) => {
          setLoading(false);
          setConfig(data);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, [record]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (record) {
      setLoadingBankAccount(true);
      dataProvider
        .getLenderBankAccount(record.id)
        .then(({ data }) => {
          setLoadingBankAccount(false);
          setBankAccounts(data);
        })
        .catch((error) => {
          setErrorBankAccount(error);
          setLoadingBankAccount(false);
        });
    }
  }, [record]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (record) {
      setLoadingTax(true);
      dataProvider
        .getLenderTaxes(record.id)
        .then(({ data }) => {
          setLoadingTax(false);
          setTaxes(data);
        })
        .catch((error) => {
          setErrorTax(error);
          setLoadingTax(false);
        });
    }
  }, [record]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TabbedShowLayout onChange={(e, v) => console.log(e, v)}>
      <Tab label="Resumo">
        <TextField label="ID" source="id" />
        <TextField label="Nome" source="name" />
        <TextField
          label="Tipo Documento"
          source="document_type"
          sx={{ textTransform: "uppercase" }}
        />
        <TextField label="Documento" source="document" />
        <DateField
          label="Criação"
          source="created_at"
          showTime
          locales="pt-BR"
        />
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
      </Tab>
      {loading ? (
        <Tab label="Config" path="config">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            my={5}
          >
            <CircularProgress />
          </Box>
        </Tab>
      ) : config ? (
        <Tab label="Config" path="config">
          <NumberField
            record={config}
            label="Limite Diário de Operação"
            source="day_amount_limit"
            options={{ style: "currency", currency: "BRL" }}
          />
          <TextField
            record={config}
            label="Horário de Abertura"
            source="opening_time"
          />
          <TextField
            record={config}
            label="Horário de Fechamento"
            source="closing_time"
          />
          <TranslatedField
            record={config}
            source="supplier_document_types"
            label="Documentos aceitos de cedente"
            prefix="document_types"
            emptyText="Não configurado"
          />
        </Tab>
      ) : error ? (
        <Tab label="Config" path="config">
          <Typography variant="p" textAlign="center" my={3}>
            Oops... Something went wrong, try again later
          </Typography>
        </Tab>
      ) : null}
      {loadingBankAccount ? (
        <Tab label="Contas Bancárias" path="bank_account">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            my={5}
          >
            <CircularProgress />
          </Box>
        </Tab>
      ) : bankAccounts ? (
        <Tab label="Contas Bancárias" path="bank_account">
          <LenderBankAccountList data={bankAccounts} />
        </Tab>
      ) : errorBankAccount ? (
        <Tab label="Contas Bancárias" path="bank_account">
          <Typography variant="p" textAlign="center" my={3}>
            Oops... Something went wrong, try again later
          </Typography>
        </Tab>
      ) : null}
      {loadingTax ? (
        <Tab label="Taxas" path="tax">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            my={5}
          >
            <CircularProgress />
          </Box>
        </Tab>
      ) : taxes ? (
        <Tab label="Taxas" path="tax">
          <LenderTaxList data={taxes} lenderId={record ? record.id : ""} />
        </Tab>
      ) : errorTax ? (
        <Tab label="Taxas" path="tax">
          <Typography variant="p" textAlign="center" my={3}>
            Oops... Something went wrong, try again later
          </Typography>
        </Tab>
      ) : null}
    </TabbedShowLayout>
  );
};

const LenderDetail = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  return (
    <Show
      actions={
        <TopToolbar>
          <Button
            variant="text"
            size="small"
            startIcon={<Add />}
            onClick={() => navigate(`/lenders/${id}/create_bank_account`)}
          >
            Nova conta bancária
          </Button>
          <Button
            variant="text"
            size="small"
            startIcon={<Add />}
            onClick={() => navigate(`/lenders/${id}/create_tax_rate`)}
          >
            Nova taxa
          </Button>
          <EditButton label="Editar Configurações" />
        </TopToolbar>
      }
    >
      <LenderDetailContent />
    </Show>
  );
};

export default LenderDetail;
