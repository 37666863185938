import React from "react";

import { Box, Card, CardContent } from "@mui/material";
import {
  ChipField,
  Datagrid,
  DateField,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  TextField,
} from "react-admin";

import { BadgeColors } from "../../../utils/constants";

const FilterSidebar = () => (
  <Box
    sx={{
      display: {
        xs: "none",
        sm: "block",
      },
      order: -1,
      width: "15em",
      marginRight: "1em",
      marginTop: "64px",
    }}
  >
    <Card>
      <CardContent>
        <FilterLiveSearch
          source="supplier_id"
          label="Cedente"
          variant="outlined"
        />
        <FilterList label="status">
          <FilterListItem
            label="Aguardando confirmação"
            value={{ status: "simulated" }}
          />
          <FilterListItem label="Confirmada" value={{ status: "proceeded" }} />
        </FilterList>
      </CardContent>
    </Card>
  </Box>
);

const SimulationList = () => {
  return (
    <List perPage={10} aside={<FilterSidebar />}>
      <Datagrid bulkActionButtons={false} size="medium" rowClick="show">
        <ReferenceField
          label="Cedente"
          source="supplier_id"
          reference="accounts"
          sortable={false}
          link={false}
        >
          <TextField source="name" label="Cedente" sortable={false} />
        </ReferenceField>
        <FunctionField
          label="Taxa"
          render={(record) => `${record.tax_perc_avg}% a.m.`}
        />
        <NumberField
          label="Valor Bruto"
          source="amount"
          locales="pt-BR"
          sortable={false}
          options={{ style: "currency", currency: "BRL" }}
        />
        <NumberField
          label="Valor Taxa"
          source="tax_amount"
          locales="pt-BR"
          sortable={false}
          options={{ style: "currency", currency: "BRL" }}
        />
        <NumberField
          label="Valor líquido"
          source="final_amount"
          locales="pt-BR"
          sortable={false}
          options={{ style: "currency", currency: "BRL" }}
        />
        <DateField
          source="created_at"
          label="Dt. Criação"
          showTime
          sortable={false}
          locales="pt-BR"
        />
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

export default SimulationList;
