import React, { useState } from "react";
import {
  DateInput,
  Form,
  required,
  useDataProvider,
  useNotify,
  useRefresh,
} from "react-admin";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ActionField from "./ActionField";
import { convertDateToString, errorManageHandler } from "../utils/helpers";
import CurrencyInput from "./CurrencyInput";

const LiquidateActionField = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const [isOpen, setOpen] = useState(false);
  const [isSaveLoading, setSaveLoading] = useState(false);

  const onClose = () => setOpen(false);

  const onSubmit = (formData) => {
    setSaveLoading(true);
    dataProvider
      .liquidateSoldPayable(formData.id, {
        amount: formData.amount,
        liquidated_at: formData.liquidated_at,
      })
      .then(() => {
        refresh();
        setSaveLoading(false);
        setOpen(false);
        notify("notifications.sold_payables.liquidated", { type: "success" });
      })
      .catch((e) => {
        const { messages } = errorManageHandler(e);
        setSaveLoading(false);
        messages.forEach((m) => notify(m, { type: "error" }));
      });
  };

  return (
    <div onClick={(clickEvent) => clickEvent.stopPropagation()}>
      <ActionField type="button" onClick={() => setOpen(true)} {...props} />
      <Dialog open={isOpen} onClose={onClose}>
        <Form onSubmit={onSubmit}>
          <DialogTitle>Liquidar título</DialogTitle>
          <DialogContent>
            <CurrencyInput source="amount" label="Valor do título" disabled />
            <DateInput
              source="liquidated_at"
              label="Data de liquidação"
              defaultValue={convertDateToString(new Date())}
              fullWidth
              validate={[
                required(),
                (date) =>
                  new Date(date) > new Date() &&
                  "Foi selecionada uma data no futuro. Um título só deve ser marcado " +
                    "como liquidado após a confirmação de liquidação consolidada junto ao fundo.",
              ]}
              sx={{ width: "550px" }}
            />
          </DialogContent>
          <DialogActions sx={{ margin: "20px" }}>
            {!isSaveLoading && (
              <Button variant="outlined" onClick={onClose}>
                Cancelar
              </Button>
            )}
            {isSaveLoading ? (
              <CircularProgress
                size={36.5}
                thickness={3}
                sx={{ padding: "4px" }}
              />
            ) : (
              <Button variant="contained" disableElevation type="submit">
                Salvar
              </Button>
            )}
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
};

export default LiquidateActionField;
