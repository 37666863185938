import React from "react";

import { Datagrid, DateField, List, TextField } from "react-admin";

const LenderList = () => {
  return (
    <List perPage={10}>
      <Datagrid bulkActionButtons={false} size="medium" rowClick="show">
        <TextField source="id" label="ID" sortable={false} />
        <TextField source="document" label="CNPJ" sortable={false} />
        <TextField source="name" label="Nome" sortable={false} />
        <DateField
          source="created_at"
          label="Dt. Criação"
          showTime
          sortable={false}
          locales="pt-BR"
        />
      </Datagrid>
    </List>
  );
};

export default LenderList;
