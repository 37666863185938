import { Tooltip } from "@mui/material";
import React from "react";
import { FunctionField } from "react-admin";

const AdditionalCostEntry = ({ cost }) => (
  <div style={{ display: "flex", flexDirection: "row" }}>
    <Tooltip title={cost.description} followCursor>
      <div>
        <FunctionField
          record={cost}
          render={(record) =>
            `${record.label} (${Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(record.unit_amount)}) x${record.quantity}:`
          }
        />
      </div>
    </Tooltip>
    <div style={{ flex: 1, minWidth: "20px" }} />
    <FunctionField
      record={cost}
      render={(record) =>
        Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(record.total_amount)
      }
    />
  </div>
);

export default AdditionalCostEntry;
