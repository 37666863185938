import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Form, required, SelectInput, TextInput } from "react-admin";

const BankAccountPopup = ({ loading, open, setOpen, submitHandler }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Create bank account</DialogTitle>
      <Form onSubmit={submitHandler} noValidate>
        <DialogContent>
          <TextInput
            variant="outlined"
            source="ba_bank_code"
            label="Código Banco"
            disabled={loading}
            fullWidth
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="ba_bank_agency"
            label="Agência"
            disabled={loading}
            fullWidth
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="ba_account_number"
            label="Número da Conta"
            disabled={loading}
            defaultValue=""
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="ba_account_digit"
            label="Dígito da Conta"
            disabled={loading}
            defaultValue=""
            validate={[required("Este campo é obrigatório")]}
          />
          <SelectInput
            variant="outlined"
            source="ba_account_type"
            label="Tipo da Conta"
            disabled={loading}
            fullWidth
            choices={[
              { id: "conta-corrente", name: "Conta Corrente" },
              { id: "conta-poupanca", name: "Conta Poupança" },
            ]}
            validate={[required("Este campo é obrigatório")]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancelar</Button>
          <Button type="submit" disabled={loading}>
            Salvar
            {loading && (
              <CircularProgress
                color="inherit"
                size={15}
                thickness={2}
                style={{ marginLeft: "10px" }}
              />
            )}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

BankAccountPopup.propTypes = {
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
};

export default BankAccountPopup;
