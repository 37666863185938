import React from "react";

import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
} from "react-admin";

import { BadgeColors } from "../../../utils/constants";

const PartnerList = () => {
  return (
    <List perPage={10}>
      <Datagrid bulkActionButtons={false} size="medium" rowClick="show">
        <TextField source="id" label="ID" sortable={false} />
        <TextField source="document" label="Documento" sortable={false} />
        <TextField source="name" label="Nome" sortable={false} />
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
        <DateField
          source="created_at"
          label="Dt. Criação"
          showTime
          sortable={false}
          locales="pt-BR"
        />
        <DateField
          source="updated_at"
          label="Dt. Atualização"
          showTime
          sortable={false}
          locales="pt-BR"
        />
      </Datagrid>
    </List>
  );
};

export default PartnerList;
