import React from "react";
import PropTypes from "prop-types";

import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  TextField,
} from "react-admin";

import CreateSimulatorButton from "./CreateSimulatorButton";

import { BadgeColors } from "../../../../utils/constants";

const SupplierPayableList = ({ supplierId }) => {
  return (
    <List
      disableSyncWithLocation
      filter={{ supplier_id: supplierId, sponsor_id: "", status: "" }}
      resource="payables"
      perPage={25}
      actions={false}
    >
      <Datagrid
        bulkActionButtons={<CreateSimulatorButton />}
        size="medium"
        rowClick="show"
      >
        <ReferenceField
          label="Sacado"
          source="sponsor_id"
          reference="accounts"
          sortable={false}
          link={false}
        >
          <TextField source="name" label="Sacado" sortable={false} />
        </ReferenceField>
        <FunctionField
          label="Título"
          render={(record) =>
            `${record.invoice_type} ${record.invoice_number} (${record.installment}/${record.installment_total})`
          }
        />
        <NumberField
          label="Valor"
          source="amount"
          options={{ style: "currency", currency: "BRL" }}
        />
        <DateField
          source="expiration_date"
          label="Vencimento"
          sortable={false}
          locales="pt-BR"
        />
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

SupplierPayableList.propTypes = {
  supplierId: PropTypes.string.isRequired,
};

export default SupplierPayableList;
