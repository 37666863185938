import { Box, Card, CardContent } from "@mui/material";
import React from "react";

import {
  ChipField,
  Datagrid,
  DateField,
  FilterLiveSearch,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  TextField,
} from "react-admin";

import { BadgeColors } from "../../../utils/constants";

const FilterSidebar = () => (
  <Box
    sx={{
      display: {
        xs: "none",
        sm: "block",
      },
      order: -1,
      width: "15em",
      marginRight: "1em",
      marginTop: "64px",
    }}
  >
    <Card>
      <CardContent>
        <FilterLiveSearch
          source="advancement_id"
          variant="outlined"
          label="ID da Antecipação"
        />
        <FilterLiveSearch
          source="sold_payable_id"
          variant="outlined"
          label="ID do Título"
        />
      </CardContent>
    </Card>
  </Box>
);

const LiquidationList = () => {
  return (
    <List perPage={10} aside={<FilterSidebar />}>
      <Datagrid bulkActionButtons={false} size="medium" rowClick="show">
        <ReferenceField
          label="Antecipação"
          source="advancement_id"
          reference="advancements"
          sortable={false}
          link={false}
        >
          <TextField source="id" label="Antecipação" />
        </ReferenceField>
        <ReferenceField
          label="Título"
          source="sold_payable_id"
          reference="sold_payables"
          sortable={false}
          link={false}
        >
          <TextField source="id" label="Título" />
        </ReferenceField>
        <DateField
          source="liquidated_at"
          label="Liquidada em"
          sortable={false}
          locales="pt-BR"
          emptyText="-"
        />
        <NumberField
          label="Valor Liquidação"
          source="amount"
          locales="pt-BR"
          sortable={false}
          options={{ style: "currency", currency: "BRL" }}
        />
        <FunctionField
          label="status"
          render={(record) => (
            <ChipField
              source="status_label"
              size="small"
              color={BadgeColors[record.status] || "default"}
              sortable={false}
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

export default LiquidationList;
