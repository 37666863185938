import React, { useEffect } from "react";
import styled from "styled-components";

import AuthLayout from "../../../layouts/AuthLayout/AuthLayout";
import { Box, Card, CircularProgress, Typography } from "@mui/material";

import { useNotify, useAuthProvider } from "react-admin";
import { errorManageHandler } from "../../../utils/helpers";

const Login = () => {
  const notify = useNotify();
  const authProvider = useAuthProvider();

  useEffect(() => {
    authProvider
      .getAuthURL()
      .then((res) => {
        // storing the logout url on the oauth2 server
        authProvider.setLogoutURL(res.data.logout_url);

        window.open(decodeURIComponent(res.data.authorization_url), "_parent");
      })
      .catch((e) => {
        console.error(e);
        const { messages } = errorManageHandler(e);
        messages.forEach((message) => {
          notify(message, { type: "error" });
        });
      });
  }, [notify, authProvider]);

  return (
    <AuthLayout>
      <FormCard>
        <Typography variant="h6">Redirecting...</Typography>
        <Box style={{ marginTop: "15px" }}>
          <CircularProgress size={40} thickness={5} />
        </Box>
      </FormCard>
    </AuthLayout>
  );
};

const FormCard = styled(Card)`
  width: 300px;
  padding: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default Login;
