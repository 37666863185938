import React from "react";
import { Box, Card, CardContent, TextField } from "@mui/material";
import { FilterList, FilterListItem, FilterLiveSearch } from "react-admin";

const FilterSidebar = ({
  textFields,
  statusList,
  setDateStart,
  setDateEnd,
}) => {
  const statusFilter = (
    <FilterList label="status">
      {statusList.map((status) => (
        <FilterListItem
          key={status.value.status}
          label={`● ${status.label}`}
          value={status.value}
        />
      ))}
    </FilterList>
  );

  return (
    <Box
      sx={{
        display: {
          xs: "none",
          sm: "block",
        },
        order: -1,
        width: "15em",
        marginRight: "1em",
        marginTop: "64px",
      }}
    >
      <Card>
        <CardContent>
          {textFields.map((textField) => (
            <FilterLiveSearch
              label={textField.label}
              source={textField.source}
              variant="outlined"
            />
          ))}
          {setDateStart && (
            <>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Data Inicial"
                type="date"
                variant="outlined"
                fullWidth
                onChange={(e) => setDateStart(e.target.value)}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Data Final"
                type="date"
                variant="outlined"
                fullWidth
                onChange={(e) => setDateEnd(e.target.value)}
              />
            </>
          )}
          {statusFilter}
        </CardContent>
      </Card>
    </Box>
  );
};

export default FilterSidebar;
