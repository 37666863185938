import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DateInput, Form, NumberInput, required, TextInput } from "react-admin";

const RegistrationDataPopup = ({
  cnpjInfo,
  existingData,
  loading,
  open,
  onClose,
  submitHandler,
}) => {
  const [formData, setFormData] = React.useState({});

  React.useEffect(() => {
    if (existingData) {
      setFormData(existingData);
    } else if (cnpjInfo) {
      setFormData({
        capital_stock: parseInt(cnpjInfo.capital_social, 10),
        size: cnpjInfo.porte.descricao,
        constitution_date: cnpjInfo.estabelecimento?.data_inicio_atividade,
        constitution_form: cnpjInfo.natureza_juridica.descricao,
        service_code:
          cnpjInfo.estabelecimento?.atividade_principal?.subclasse +
          " " +
          cnpjInfo.estabelecimento?.atividade_principal?.descricao,
        situacao_receita_federal: cnpjInfo.estabelecimento?.situacao_cadastral,
        ...formData,
      });
    }
  }, [cnpjInfo, existingData, open]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Cadastrar dados</DialogTitle>
      <Form record={formData} onSubmit={submitHandler} noValidate>
        <DialogContent>
          <NumberInput
            variant="outlined"
            source="annual_gross_revenue"
            label="Receita anual"
            disabled={loading}
            fullWidth
            validate={[required("Este campo é obrigatório")]}
          />
          <NumberInput
            variant="outlined"
            source="capital_stock"
            label="Capital Social Abertura"
            disabled={loading}
            fullWidth
            validate={[required("Este campo é obrigatório")]}
          />
          <DateInput
            variant="outlined"
            source="constitution_date"
            label="Data de criação"
            disabled={loading}
            fullWidth
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="constitution_form"
            label="Forma Constituição (S.A., LTDA, MEI)"
            disabled={loading}
            fullWidth
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="service_code"
            label="CNAE Atividade Principal"
            disabled={loading}
            fullWidth
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="situacao_receita_federal"
            label="Situação da Empresa na Receita"
            disabled={loading}
            fullWidth
            validate={[required("Este campo é obrigatório")]}
          />
          <TextInput
            variant="outlined"
            source="size"
            label="Porte da empresa"
            disabled={loading}
            fullWidth
            validate={[required("Este campo é obrigatório")]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button type="submit" disabled={loading}>
            Salvar
            {loading && (
              <CircularProgress
                color="inherit"
                size={15}
                thickness={2}
                style={{ marginLeft: "10px" }}
              />
            )}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

RegistrationDataPopup.propTypes = {
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
};

export default RegistrationDataPopup;
