import React from "react";

import { Layout } from "react-admin";

import Menu from "./Components/Menu";
import AppBar from "./Components/AppBar";

const MainLayout = (props) => {
  return <Layout {...props} menu={Menu} appBar={AppBar} />;
};

export default MainLayout;
