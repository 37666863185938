import { useEffect } from "react";
import styled from "styled-components";

import AuthLayout from "../../../layouts/AuthLayout/AuthLayout";
import { Box, Card, CircularProgress, Typography } from "@mui/material";

import { useAuthProvider } from "react-admin";

const Logout = () => {
  const authProvider = useAuthProvider();

  useEffect(() => {
    // logout the user from the peerbnk oauth2 server
    const logoutURL = authProvider.getLogoutURL();

    if (logoutURL && logoutURL !== "") {
      window.location.href = logoutURL;
    }
  }, [authProvider]);

  return (
    <AuthLayout>
      <FormCard>
        <Typography variant="h6">Redirecting...</Typography>
        <Box style={{ marginTop: "15px" }}>
          <CircularProgress size={40} thickness={5} />
        </Box>
      </FormCard>
    </AuthLayout>
  );
};

const FormCard = styled(Card)`
  width: 300px;
  padding: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default Logout;
