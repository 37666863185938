import React from "react";
import { TextInput } from "react-admin";

const CurrencyInput = ({ unit, source, label, ...props }) => (
  <TextInput
    source={source}
    label={label}
    fullWidth
    defaultValue={0}
    format={(value) =>
      new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(unit === "cents" ? value / 100.0 : value)
    }
    parse={(BRLstring) =>
      BRLstring ? parseInt(BRLstring.replace(/[R$.,]+/g, "")) : 0
    }
    {...props}
  />
);

export default CurrencyInput;
